import React from 'react';
import { Link } from 'gatsby';
import { Layout } from '../../../components/Layout';

const UtilityConstructionSurveys = () => (
  <Layout
    title="Utility Construction Surveys Calgary & Red Deer | Axiom Geomatics"
    description="A Utility Construction Survey determines the future location where utilities will be located on a property."
    keywords="municipal survey, construction survey, utility services, not locates, water line, fiber-optic line, deep services, shallow services, power line, access right-of-way"
  >
    <main>
      <h1>Utility Construction Surveys Calgary & Area</h1>
      <p>
        A <strong>Utility Construction Survey</strong> is a municipal survey that determines the
        location and depth of new utilities on a property. These utitlies include fiber-optic lines,
        power, water and sewage lines and natural gas lines.
      </p>

      <p>
        Appropriate planning for placement of utilities is required because placing certain services
        at too shallow a depth will cause major problems, e.g. a water or sewer line buried above
        the frost-line will freeze and explode.
      </p>

      <p>
        <a href="https://axiomgeomatics.ca/about-us">Surveyors at Axiom Geomatics</a> are specially
        trained in three-dimensional (3-D) survey techniques to ensure deep and shallow services are
        appropriately located.
      </p>

      <h2>
        <abbr title="Frequently Asked Questions">FAQs</abbr>
      </h2>

      <ul>
        <li>
          <Link to="#mark-existing">Do you survey and mark existing utility lines?</Link>
        </li>
      </ul>

      <h3 id="mark-existing">Do you survey and mark existing utility lines?</h3>
      <p>
        No. In Alberta there is a service,{' '}
        <a href="http://albertaonecall.com/" target="one-call">
          Alberta One Call
        </a>
        , that will take care of this need free of charge, just call them at least a week before you
        plan on digging.
      </p>
    </main>
  </Layout>
);

export default UtilityConstructionSurveys;
